import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RequestService } from './request.service';
import {
  AddProfileInFoldersRequestData,
  AddProfileListInFoldersRequestData,
  AdminLogRequestBody,
  AdminPanelApi,
  AdminSearchDto,
  AutocompleteApi,
  CampaignApi,
  CatalogApi,
  Comment,
  CompanyApi,
  ContactUserDto,
  CreditName,
  EditStatusesInfo,
  ExportApi,
  Folder,
  FolderApi,
  FolderMassActionDto,
  FolderStatus,
  GetAllFoldersParams,
  Locale,
  PaymentApi,
  ProfileApi,
  ProfileContactsQueryParams,
  QueryApi,
  QueryListItem,
  RestApi,
  SearchQueryRequestInfo,
  ServiceApi,
  StatisticApi,
  Tag,
  TagApi,
  User,
  UserApi
} from '../../models';
import { SearchCategoryTypeEnum } from '../../enums';
import { cyrillicRegExp } from '../../utilities';
import { ExportProfilesRequestPayload, ProfileExportSettingAPIPayload } from '../../models/export';
import { CampaignListRequestPayload } from '../../models/campaign/campaign';
import { CampaignCreationPayload } from '../../models/campaign/campaign-settings';

@Injectable({
  providedIn: 'root'
})
export class RestService extends RequestService {
  constructor(public http: HttpClient) {
    super(http);
  }

  Profile(): ProfileApi {
    return {
      getCandidate: (id: string) => {
        return this.makeRequest('get', '/profile/get', {
          params: {
            profileId: id
          }
        });
      },
      getSimilarCandidates: (id: string) => {
        return this.makeRequest('get', '/profile/getSimilar', {
          params: {
            profileId: id
          }
        });
      },
      follow: (id: string, follow: boolean) => {
        return this.makeRequest('get', '/profile/follow', {
          params: {
            profileId: id,
            follow
          }
        });
      },
      getComments: (docId: string) => {
        return this.makeRequest('get', '/profile/getComments', {
          params: {
            docId
          }
        });
      },
      removeComment: (commentId: string, docId: string) => {
        return this.makeRequest('get', '/profile/removeComment', {
          params: {
            commentId,
            docId
          }
        });
      },
      editComment: (commentId: string, docId: string, comment: string) => {
        return this.makeRequest('post', '/profile/editComment', null, {
          commentId,
          docId,
          comment
        });
      },
      addComment: (comment: Comment) => {
        return this.makeRequest('post', '/profile/addComment', null, comment);
      },
      search: (queryString: string) => {
        return this.makeRequest('get', `/profile/search${queryString}`);
      },
      filters: (queryString: string) => {
        return this.makeRequest('get', `/profile/filters${queryString}`);
      },
      getSubscriptions: (queryString: string) => {
        return this.makeRequest('get', `/profile/getSubscriptions${queryString}`);
      },
      getProfileContacts: (profileId: string = null, url: string = null) => {
        const params: ProfileContactsQueryParams = {};

        if (profileId) {
          params.profileId = profileId;
        } else {
          params.url = url;
        }

        return this.makeRequest('get', '/profile/getProfileContacts', {
          params
        });
      },
      openProfileContacts: (profileId: string = null, url: string = null) => {
        const params: ProfileContactsQueryParams = {};

        if (profileId) {
          params.profileId = profileId;
        } else {
          params.url = url;
        }

        return this.makeRequest('get', '/profile/openProfileContacts', {
          params
        });
      },
      searchProfileContacts: (profileId: string = null, url: string = null) => {
        const params: ProfileContactsQueryParams = {};

        if (profileId) {
          params.profileId = profileId;
        } else {
          params.url = url;
        }

        return this.makeRequest('get', '/profile/searchProfileContacts', {
          params
        });
      },
      getImage: (location: string) => {
        return this.makeRequest(
          'get',
          '/profile/getImage',
          {
            params: {
              location
            }
          },
          null,
          {
            isFullMatch: false,
            isFile: true
          }
        );
      },
      getProfileLinks: (docId: string) => {
        return this.makeRequest('get', '/profile/getProfileLinks', {
          params: {
            docId
          }
        });
      },
      addContact: (dto: ContactUserDto) => {
        return this.makeRequest('post', '/profile/addContact', null, dto);
      },
      deleteContact: (dto: ContactUserDto) => {
        return this.makeRequest('post', '/profile/deleteContact', null, dto);
      },
      updateContact: (dto: ContactUserDto) => {
        return this.makeRequest('post', '/profile/updateContact', null, dto);
      },
      flagContact: (dto: ContactUserDto) => {
        return this.makeRequest('post', '/profile/flagContact', null, dto);
      }
    };
  }

  Tag(): TagApi {
    return {
      getAllTags: () => this.makeRequest('get', '/tag/getAllTags'),
      removeTag: (tagId: string) => this.makeRequest('delete', `/tag/removeTag/${tagId}`),
      createTag: (tag: Tag) => this.makeRequest('post', '/tag/createTag', null, tag),
      getProfileTags: (docId: string) => {
        return this.makeRequest('get', '/tag/getProfileTags', {
          params: {
            docId
          }
        });
      },
      removeProfileTag: (tagId: string, profileId: string, docId: string) => {
        return this.makeRequest('get', `/tag/removeProfileTag`, {
          params: {
            tagId,
            profileId,
            docId
          }
        });
      },
      addProfileTag: (tagId: string, profileId: string, docId: string) => {
        return this.makeRequest(
          'post',
          `/tag/addProfileTag?tagId=${tagId}&profileId=${profileId}&docId=${docId}`
        );
      },
      changeTagName: (tagId: string, tagName: string) => {
        return this.makeRequest('get', '/tag/changeTagName', {
          params: {
            tagId,
            newName: tagName
          }
        });
      },
      getTagCount: () => {
        return this.makeRequest('get', '/tag/count', { responseType: 'text' });
      }
    };
  }

  Statistic(): StatisticApi {
    return {
      getCandidateStatistic: (profileId: string) => {
        return this.makeRequest('get', '/statistic/personStatistic', {
          params: {
            profileId
          }
        });
      }
    };
  }

  Catalog(): CatalogApi {
    return {
      getSorting: () => {
        return this.makeRequest('get', '/catalog/getSorting');
      }
    };
  }

  User(): UserApi {
    return {
      register: (user: User) => {
        return this.makeRequest('post', `/user/register`, null, user);
      },
      login: (user: User) => {
        return this.makeRequest('post', '/user/login', { observe: 'response' }, user);
      },
      logout: () => {
        return this.makeRequest('get', '/user/logout');
      },
      refresh: () => {
        return this.makeRequest('get', '/user/refresh', {
          observe: 'response'
        });
      },
      getUserList: () => {
        return this.makeRequest('get', '/user/list');
      },
      addUserPhoto: (userPhoto: FormData) => {
        return this.makeRequest('post', '/user/addPhoto', null, userPhoto, {
          isFullMatch: false,
          isFile: true
        });
      },
      getUserPhoto: (userId: string) => {
        return this.makeRequest(
          'get',
          `/user/getPhoto`,
          {
            params: {
              userId
            }
          },
          null,
          {
            isFullMatch: false,
            isFile: true
          }
        );
      },
      getHash: () => {
        return this.makeRequest('get', '/user/getHash', {
          responseType: 'text'
        });
      },
      addHash: (hash: string, newUser: User) => {
        return this.makeRequest('post', `/user/add/${hash}`, null, newUser);
      },
      changeName: (user: User) => {
        return this.makeRequest('post', '/user/changeName', null, user);
      },
      changePassword: (user: User) => {
        return this.makeRequest('post', '/user/changePassword', { observe: 'response' }, user);
      },
      changeEmail: (email: string, user: User) => {
        return this.makeRequest(
          'post',
          `/user/changeEmail/${email}`,
          { observe: 'response' },
          user
        );
      },
      removeUser: (userId: string) => {
        return this.makeRequest('delete', `/user/remove/${userId}`);
      },
      changeRole: (role: string, userId: string) => {
        return this.makeRequest('post', `/user/role/${role}/${userId}`);
      },
      lockUser: (isNotLocked: boolean, userId: string) => {
        return this.makeRequest('post', `/user/unlock/${isNotLocked}/${userId}`);
      },
      getUser: (userId: string) => {
        return this.makeRequest('get', `/user/get/${userId}`);
      },
      updateLanguage: (locale: Locale) => {
        return this.makeRequest('put', `/user/language/${locale}`);
      },
      updateOnboardingStatus: (status: boolean) => {
        return this.makeRequest('put', `/user/onboardingStatus/${status}`);
      },
      requestPasswordChange: (login: string, lang: Locale) => {
        return this.makeRequest('get', `/user/requestPasswordChange/${login}/${lang}`);
      },
      submitPasswordChange: (key: string, lang: Locale, newPassword: string) => {
        return this.makeRequest(
          'post',
          `/user/submitPasswordChange/${key}/${lang}`,
          { observe: 'response' },
          { newPassword }
        );
      },
      isTest: () => {
        return this.makeRequest('get', '/user/test', { responseType: 'text' });
      },
      getUserGooglePlace: () => {
        return this.makeRequest('get', '/user/getUserGooglePlace');
      },
      saveUtmAndGa: (utmSource: string, gaCookie: string) => {
        return this.makeRequest('get', '/user/saveUtmAndGa', {
          params: {
            ['utm_source']: utmSource,
            ['ga_cookie']: gaCookie
          }
        });
      },
      getApiKey: () => {
        return this.makeRequest('get', '/user/getApiKey', { responseType: 'text' });
      },
      generateNewApiKey: () => {
        return this.makeRequest('get', '/user/generateNewApiKey', { responseType: 'text' });
      },
      removeApiKey: () => {
        return this.makeRequest('get', '/user/removeApiKey', { responseType: 'text' });
      }
    };
  }

  Autocomplete(): AutocompleteApi {
    return {
      autocomplete: (type: SearchCategoryTypeEnum, word: string) => {
        const encodedWord: string = word?.match(cyrillicRegExp) ? word : encodeURIComponent(word);

        return this.makeRequest('get', '/autocomplete/autocomplete', {
          params: {
            type,
            word: encodedWord
          }
        });
      },
      searchObjects: (queryString: string) => {
        return this.makeRequest('get', `/autocomplete/searchObjects${queryString}`);
      }
    };
  }

  Query(): QueryApi {
    return {
      add: (searchQuery: SearchQueryRequestInfo) => {
        return this.makeRequest('post', '/query/add', null, searchQuery);
      },
      getList: (withAutocompleteDto: boolean = false, pageNumber: number = null) => {
        let params: QueryListItem = {
          withAutocompleteDto
        };

        if (pageNumber) {
          params.pageNumber = pageNumber;
        }

        return this.makeRequest('get', `/query/getList`, { params });
      },
      removeSavedSearchQuery: (id: number) => {
        return this.makeRequest('delete', `/query/removeQuery/${id}`);
      },
      renameSavedSearchQuery: (id: number, newName: string) => {
        return this.makeRequest('get', '/query/rename', {
          params: {
            searchQueryId: id,
            name: newName
          }
        });
      },
      getSearchHistory: (pageNumber: number) => {
        return this.makeRequest('get', '/query/getSearchHistory', {
          params: {
            pageNumber
          }
        });
      },
      getCountSavedSearches: () => {
        return this.makeRequest('get', '/query/countSavedSearches', {
          responseType: 'text'
        });
      },
      getCountSearchesHistory: () => {
        return this.makeRequest('get', '/query/countSearchHistory', {
          responseType: 'text'
        });
      }
    };
  }

  Company(): CompanyApi {
    return {
      getCompanyInfo: (companyId: number) => {
        return this.makeRequest('get', '/company/getCompanyInfo', {
          params: {
            companyId
          }
        });
      },
      getEmployees: (companyId: number, pageNumber: number) => {
        return this.makeRequest('get', '/company/getEmployees', {
          params: {
            companyId,
            pageNumber
          }
        });
      }
    };
  }

  Export(): ExportApi {
    return {
      getCVSFile: (id: string) => {
        return this.makeRequest('get', `/export/get/${id}`, null, null, {
          isFile: true,
          isFullMatch: false
        });
      },
      getExportList: (query: string) => {
        return this.makeRequest('get', `/export/list${query}`);
      },
      createProfileExport: (searchQuery: string, payload: ExportProfilesRequestPayload) => {
        return this.makeRequest('post', `/export/profile${searchQuery}`, null, payload);
      },
      getExportState: (id: string) => {
        return this.makeRequest('post', `/export/status/${id}`);
      },
      getExportSettings: () => {
        return this.makeRequest('get', '/export/setting/get');
      },
      changeProfileExportSetting: (payload: ProfileExportSettingAPIPayload) => {
        return this.makeRequest('post', '/export/setting/profile', null, payload);
      }
    };
  }

  Payment(): PaymentApi {
    return {
      createPaymentForCardIntegration: () => {
        return this.makeRequest('get', '/payment/createPaymentForCardIntegration');
      },
      makePaymentByOrgId: () => {
        return this.makeRequest('get', '/payment/makePaymentByOrgId');
      },
      getAccount: () => {
        return this.makeRequest('get', '/payment/account');
      },
      getCardList: () => {
        return this.makeRequest('get', '/payment/cards');
      },
      makeDefaultPaymentCard: (id: number) => {
        return this.makeRequest('get', `/payment/makeDefaultPaymentCard/${id}`);
      },
      deletePaymentCard: (id: number) => {
        return this.makeRequest('delete', `/payment/card/${id}`);
      },
      getPayments: (page: number = 0, size: number = 15) => {
        return this.makeRequest('get', `/payment/payments/${page}/${size}`);
      },
      getAllCreditPacks: () => {
        return this.makeRequest('get', `/payment/getAllCreditPacks`);
      },
      buyCredits: (name: CreditName) => {
        return this.makeRequest('get', `/payment/buyCredits/${name}`);
      },
      changeTariff: (months: number, users: number) => {
        return this.makeRequest('get', `/payment/changeTariff/${months}/${users}`);
      },
      changeTariffNow: (months: number, users: number) => {
        return this.makeRequest('get', `/payment/changeTariffNow/${months}/${users}`);
      },
      endTrialAndCreatePayment: () => {
        return this.makeRequest('get', '/payment/endTrialAndCreatePayment');
      },
      rejectTariffChange: () => {
        return this.makeRequest('get', '/payment/rejectTariffChange');
      },
      price: (userNumber: number) => {
        return this.makeRequest('get', '/payment/price', {
          params: {
            userCount: userNumber
          }
        });
      },
      getClientSecret: () => {
        return this.makeRequest('get', '/payment/addPaymentMethod', {
          responseType: 'text'
        });
      },
      endFreeAndMakePayment: () => {
        return this.makeRequest('get', '/payment/endFreeAndMakePayment', {
          responseType: 'text'
        });
      }
    };
  }

  AdminPanel(): AdminPanelApi {
    return {
      getSortedAdminDataList: (data: AdminSearchDto) => {
        return this.makeRequest('post', '/adminPanel/getSortedAdminDataList', null, data);
      },
      getAdminPanelLog: (filter: AdminLogRequestBody) => {
        return this.makeRequest('post', '/adminPanel/getAdminPanelLog', null, filter);
      },
      getCompanies: () => {
        return this.makeRequest('get', '/adminPanel/getCompanies');
      }
    };
  }

  Rest(): RestApi {
    return {
      sendLink: (link: string) => {
        return this.makeRequest('get', link, { responseType: 'string' });
      },
      sendImage: (link: string) => {
        return this.makeRequest('get', link, { responseType: 'blob' }, null, {
          isFullMatch: true,
          isFile: false
        });
      }
    };
  }

  Folder(): FolderApi {
    return {
      getAllStatuses: () => {
        return this.makeRequest('get', '/folder/getAllStatuses');
      },
      addStatus: (status: FolderStatus) => {
        return this.makeRequest('post', '/folder/addStatus', null, status);
      },
      deleteStatus: (id: string) => {
        return this.makeRequest('get', '/folder/deleteStatus', {
          params: { id }
        });
      },
      editStatuses: (statuses: FolderStatus[]) => {
        const info: EditStatusesInfo = { statuses };

        return this.makeRequest('post', '/folder/editStatuses', null, info);
      },
      getCount: () => {
        return this.makeRequest('get', '/folder/count');
      },
      getAllFolders: (withCandidates: boolean, docIds: string = '') => {
        const params: GetAllFoldersParams = { withCandidates };

        if (docIds) {
          params.docIds = docIds;
        }

        return this.makeRequest('get', '/folder/getAllFolders', {
          params
        });
      },
      addFolder: (folder: Folder) => {
        return this.makeRequest('post', '/folder/addFolder', null, folder);
      },
      deleteFolder: (id: string) => {
        return this.makeRequest('get', '/folder/deleteFolder', {
          params: {
            id
          }
        });
      },
      editFolder: (folder: Folder) => {
        return this.makeRequest('post', '/folder/editFolder', null, folder);
      },
      getFolder: (folder: Folder) => {
        return this.makeRequest('post', '/folder/getFolder', null, folder);
      },
      addProfileInFolders: (data: AddProfileInFoldersRequestData) => {
        return this.makeRequest('post', '/folder/addProfileInFolders', null, data);
      },
      addProfileListInFolder: (queryString: string, data: AddProfileListInFoldersRequestData) => {
        return this.makeRequest('post', `/folder/addProfileListInFolder${queryString}`, null, data);
      },
      removeProfilesFromFolder: (data: FolderMassActionDto) => {
        return this.makeRequest('post', '/folder/removeProfilesFromFolder', data);
      },
      removeProfileFromFolders: (docId: string, folderIds: string[]) => {
        return this.makeRequest('get', '/folder/removeProfileFromFolders', {
          params: {
            folderIds,
            docId
          }
        });
      },
      statusStatistics: (folderIds: string) => {
        return this.makeRequest('get', '/folder/statusStatistics', {
          params: {
            folderIds
          }
        });
      },
      multipleCopy: (data: FolderMassActionDto) => {
        return this.makeRequest('post', '/folder/multipleCopy', null, data);
      },
      multipleMove: (data: FolderMassActionDto) => {
        return this.makeRequest('post', '/folder/multipleMove', null, data);
      },
      changeStatus: (data: FolderMassActionDto) => {
        return this.makeRequest('post', '/folder/changeStatus', null, data);
      }
    };
  }

  Service(): ServiceApi {
    return {
      price: (userNumber: number) => {
        return this.makeRequest('get', '/service/price', {
          params: {
            userCount: userNumber
          }
        });
      },
      priceV2: () => {
        return this.makeRequest('get', '/service/v2/price');
      }
    };
  }

  Campaign(): CampaignApi {
    return {
      getCampaignByUser: () => {
        return this.makeRequest('get', '/campaign/user');
      },

      getAllCampaigns: (queryParams: string) => {
        return this.makeRequest('get', '/campaign/all' + queryParams);
      },

      getCampaignById: (id: string) => {
        return this.makeRequest('get', `/campaign/${id}`);
      },

      createNewCampaign: (campaign: CampaignCreationPayload) => {
        return this.makeRequest('post', '/campaign/', null, campaign);
      },

      editCampaign: (campaign: CampaignCreationPayload) => {
        return this.makeRequest('put', '/campaign/', null, campaign);
      },

      archiveCampaign: (id: string) => {
        return this.makeRequest('put', `/campaign/${id}/archive`);
      },

      completeCampaign: (id: string) => {
        return this.makeRequest('put', `/campaign/${id}/complete`);
      },

      toggleCampaign: (id: string) => {
        return this.makeRequest('put', `/campaign/${id}/toggle`);
      },

      deleteCampaign: (id: string) => {
        return this.makeRequest('delete', `/campaign/${id}`);
      },

      getEmailFilters: () => {
        return this.makeRequest('get', '/campaign/email/filter');
      },

      createEmailFilter: (filter: any) => {
        return this.makeRequest('post', '/campaign/email/filter', null, filter);
      },

      saveNewSchedule: (schedule: any) => {
        return this.makeRequest('post', '/campaign/schedule/', null, schedule);
      },

      getScheduleList: () => {
        return this.makeRequest('get', '/campaign/schedule/list');
      },

      getAvailableZoneIds: () => {
        return this.makeRequest('get', '/campaign/schedule/zoneids');
      },

      getEmailCredentials: () => {
        return this.makeRequest('get', '/campaign/email/credential/');
      },

      getEmailCredential: (id: string) => {
        return this.makeRequest('get', '/campaign/email/credential/' + id);
      },

      saveEmailCredential: (credential: any) => {
        return this.makeRequest('post', '/campaign/email/credential/', null, credential);
      }
    };
  }
}
