import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FontsService {
  private readonly criticalFonts = [
    {
      name: 'Proxima Nova',
      url: 'assets/fonts/ProximaNova-Regular.woff2'
    },
    {
      name: 'Proxima Nova Semibold',
      url: 'assets/fonts/ProximaNova-Semibold.woff2'
    },
    {
      name: 'Proxima Nova Bold',
      url: 'ProximaNova-Bold.woff2'
    }
  ];

  private readonly nonCriticalFonts = [
    {
      name: 'Proxima Nova Bold Italic',
      url: 'assets/fonts/ProximaNova-BoldIt.woff2'
    }
  ];

  loadCriticalFonts(): void {
    this.criticalFonts.forEach((font) => {
      const link = document.createElement('link');
      link.href = font.url;
      link.rel = 'stylesheet';

      link.setAttribute('media', 'print');
      document.head.appendChild(link);
    });
  }

  loadNonCriticalFonts(): void {
    this.nonCriticalFonts.forEach((font) => {
      const fontFace = new FontFace(font.name, `url(${font.url})`);

      (document as any).fonts.add(fontFace);
      fontFace
        .load()
        .then(() => {})
        .catch(() => {});
    });
  }
}
