import { Injectable } from '@angular/core';
import JsPDF, { HTMLFontFace } from 'jspdf';
import { Observable, Subject } from 'rxjs';
import {
  FontData,
  FontsInfo,
  PagesInfoForPdfGeneration
} from '../../../../shared/models/candidate/candidateCVGenerator';

interface CustomFontsImportData {
  proximaNovaRegular?: string;
  proximaNovaSemibold?: string;
  proximaNovaBold?: string;
  proximaNovaBoldItalic?: string;
}

interface GeneratePdfInfo {
  jsPDF: JsPDF;
  margin: number;
  scale: number;
  fontFaces: HTMLFontFace[];
  fileName: string;
}

enum CVSizesPt {
  imageWidth = 595.28,
  pageHeight = 841.89
}

@Injectable()
export class CandidateCVGeneratorService {
  constructor() {}

  private startGeneratePDFSubject = new Subject<number>();

  startGeneratePDF$: Observable<number> = this.startGeneratePDFSubject.asObservable();

  startGeneratePDF(pageNumber: number): void {
    this.startGeneratePDFSubject.next(pageNumber);
  }

  generatePDF(pagesInfo: PagesInfoForPdfGeneration): Promise<FontsInfo> {
    return new Promise((resolve) => {
      const { element, wrapper, pageNumber, fontsInfo } = pagesInfo;

      if (wrapper && element) {
        const jsPDF = new JsPDF('p', 'pt', 'a4', true);

        CandidateCVGeneratorService.getHtmlFontFacesPromise(jsPDF, fontsInfo)
          .then((fontsInfo: FontsInfo) => {
            const pdfInfo: GeneratePdfInfo = this.generatePdfPageInfo(
              jsPDF,
              fontsInfo?.htmlFontFaces,
              pagesInfo
            );
            const pageElements: Element[] = CandidateCVGeneratorService.getListOfPageElements(
              wrapper,
              pageNumber
            );

            const addPagesToPDF = async () => {
              for (const [index, pageElement] of pageElements.entries()) {
                await CandidateCVGeneratorService.addPageToPDF(
                  pageElement as HTMLElement,
                  index,
                  pdfInfo
                );
              }

              CandidateCVGeneratorService.savePDF(pdfInfo, pageNumber);
              resolve(fontsInfo);
            };

            addPagesToPDF().then();
          })
          .catch(() => {
            resolve(null);
          });
      } else {
        resolve(null);
      }
    });
  }

  getPdfImageScale(element: HTMLElement, margin: number = 0): number {
    const srcWidth = element.scrollWidth;

    return (CVSizesPt.imageWidth - margin * 2) / srcWidth;
  }

  private generatePdfPageInfo(
    jsPDF: JsPDF,
    fontFaces: HTMLFontFace[] = [],
    pagesInfo: PagesInfoForPdfGeneration
  ): GeneratePdfInfo {
    const { profileFullName, element } = pagesInfo;
    const fileName: string = CandidateCVGeneratorService.generateCVName(profileFullName);
    const margin = 0;
    const scale: number = this.getPdfImageScale(element, margin);

    return {
      jsPDF,
      margin,
      scale,
      fontFaces,
      fileName
    };
  }

  private static addPageToPDF(
    element: HTMLElement,
    currentPage: number,
    info: GeneratePdfInfo
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      const { jsPDF, scale, fontFaces, margin } = info;
      const x = currentPage === 0 ? undefined : 0;
      const y: number =
        currentPage === 0 ? undefined : jsPDF.internal.pageSize.height * currentPage;

      jsPDF.html(element, {
        margin,
        html2canvas: {
          scale,
          ignoreElements: CandidateCVGeneratorService.html2CanvasIgnoreMethods
        },
        fontFaces,
        x,
        y,
        callback: () => {
          resolve();
        }
      });
    });
  }

  private static html2CanvasIgnoreMethods(element: HTMLElement): boolean {
    return (
      element?.id === 'intercom-frame' ||
      element?.classList?.contains('intercom-lightweight-app') ||
      element?.tagName === 'META' ||
      element?.tagName === 'NOSCRIPT' ||
      element?.tagName === 'IFRAME' ||
      (element?.tagName === 'LINK' && (element as HTMLLinkElement)?.rel === 'preload')
    );
  }

  private static getListOfPageElements(element: HTMLElement, pageNumber: number): Element[] {
    return Array.from(Array(pageNumber).keys())
      .map((item: number) => item + 1)
      .map((pageItem: number) => {
        const specificClass = pageItem === 1 ? '' : `.page-${pageItem}`;

        return element.querySelector(`.candidate-cv${specificClass}`);
      })
      .filter((element: HTMLElement) => !!element);
  }

  private static savePDF(pdfInfo: GeneratePdfInfo, pageNumber: number): void {
    const { jsPDF, fileName } = pdfInfo;

    // clear blank pages:
    if (jsPDF.internal.pages?.length > pageNumber) {
      jsPDF.deletePage(jsPDF.internal.pages?.length - 1);
    }

    // final save:
    jsPDF.save(fileName);
  }

  // *** Custom fonts settings: ***

  private static getHtmlFontFacesPromise(jsPDF: JsPDF, fontsInfo: FontsInfo): Promise<FontsInfo> {
    return new Promise<FontsInfo>((resolve) => {
      if (fontsInfo?.fontDataList && fontsInfo.htmlFontFaces) {
        this.setFontsToJsPDF(jsPDF, fontsInfo.fontDataList);
        resolve(fontsInfo);
      } else {
        import('../../components/download-profile-cv/download-profile-cv-content/fonts')
          .then((data: CustomFontsImportData) => {
            const fontDataList: FontData[] = this.getFontDataList(data);

            this.setFontsToJsPDF(jsPDF, fontDataList);

            const htmlFontFaces: HTMLFontFace[] = this.getHtmlFontFaces(fontDataList);
            const fontsInfo: FontsInfo = { fontDataList, htmlFontFaces };

            resolve(fontsInfo);
          })
          .catch(() => {
            resolve(null);
          });
      }
    });
  }

  private static setFontsToJsPDF(document: JsPDF, fontDataList: FontData[]): void {
    fontDataList.forEach((fontData: FontData) => {
      document.addFileToVFS(fontData.fileName, fontData.fileContent);
      document.addFont(
        fontData.fileName,
        fontData.id,
        fontData.fontStyle,
        '400',
        'StandardEncoding',
        true
      );
    });
  }

  private static getHtmlFontFaces(fontDataList: FontData[]): HTMLFontFace[] {
    return fontDataList.map((fontData: FontData) => {
      return {
        family: fontData.id,
        style: fontData.fontStyle,
        weight: '400',
        src: [
          {
            url: fontData.fileName,
            format: 'truetype'
          }
        ]
      };
    });
  }

  private static getFontDataList(data: CustomFontsImportData = {}): FontData[] {
    const {
      proximaNovaRegular = '',
      proximaNovaSemibold = '',
      proximaNovaBold = '',
      proximaNovaBoldItalic = ''
    } = data;

    return [
      {
        id: 'Proxima Nova',
        fileName: 'ProximaNova-Regular.ttf',
        fileContent: proximaNovaRegular,
        fontStyle: 'normal'
      },
      {
        id: 'Proxima Nova Semibold',
        fileName: 'ProximaNova-Semibold.ttf',
        fileContent: proximaNovaSemibold,
        fontStyle: 'normal'
      },
      {
        id: 'Proxima Nova Bold',
        fileName: 'ProximaNova-Bold.ttf',
        fileContent: proximaNovaBold,
        fontStyle: 'normal'
      },
      {
        id: 'Proxima Nova Bold Italic',
        fileName: 'ProximaNova-BoldIt.ttf',
        fileContent: proximaNovaBoldItalic,
        fontStyle: 'italic'
      }
    ];
  }

  // Other generate PDF methods:

  private static generateCVName(candidateName: string = 'profile'): string {
    const subjectName = 'CV';
    const separator = '_';
    const format = 'pdf';
    const fileExtension = `.${format}`;
    const handledName = CandidateCVGeneratorService.getCandidateNameForFile(
      candidateName,
      separator
    );

    return `${handledName}${separator}${subjectName}${fileExtension}`;
  }

  private static getCandidateNameForFile(candidateName: string, separator: string): string {
    const defaultCandidateName = 'profile';
    let handledName: string;

    if (candidateName) {
      handledName = candidateName.trim().split(' ').join(separator);
    } else {
      handledName = defaultCandidateName;
    }

    return handledName;
  }
}
