import { NumericType } from '../../types';
import { Language } from '../../models';

export class LocalizationData {
  static numericTypeList: NumericType[] = [
    'YEAR',
    'MONTH',
    'MONTH_UPPERCASE',
    'DAY',
    'HOUR',
    'OTHER_USER',
    'USER',
    'TAGS_AVAILABLE_INFO',
    'ON_YOUR_TEAM_INFO',
    'CANDIDATE_ABLATIVE',
    'TAG',
    'PROFILE',
    'FOR_PROFILES_NUMBER',
    'FOR_PROFILES_STATUS',
    'CONTACT_CREDITS',
    'EXPORT_CREDITS',
    'OPEN_CONTACTS',
    'CAMPAIGN_LIST_NUMBER_INFO'
  ];

  static landingLangList: Language[] = [
    {
      locale: 'en',
      label: 'English',
      selected: false,
      selectedLabel: 'In English',
      selectedLabelMobile: 'Eng'
    } // todo: redesign - uncomment, temporary hidden:
    // {
    //   locale: 'ua',
    //   label: 'Українська',
    //   selected: true,
    //   selectedLabel: 'Українською',
    //   selectedLabelMobile: 'Укр'
    // },
    // {
    //   locale: 'pl',
    //   label: 'Polski',
    //   selected: false,
    //   selectedLabel: 'Polski',
    //   selectedLabelMobile: 'Pol'
    // }
  ];

  static systemLangList: Language[] = [
    {
      locale: 'en',
      label: 'Eng.',
      selected: false
    } // todo: redesign - uncomment, temporary hidden:
    // {
    //   locale: 'ua',
    //   label: 'Укр.',
    //   selected: false
    // },
    // {
    //   locale: 'pl',
    //   label: 'Pol.',
    //   selected: false
    // }
  ];
}
