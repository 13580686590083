import { SystemEnvironment } from '../app/shared/models';

export const environment: SystemEnvironment = {
  production: true,
  name: 'stage',
  backEndUrl: 'https://test2.wandify.io/api',
  stripePublishableKey:
    'pk_test_51NpoHUKmiloxdvB2rL18FwiUCoWAuKhJVB47gBtCvrXoX5clQWOSnrW09184kLec1TakNEM4JJe0zaxxKjDp2PW300Gg8udaGw',
  defaultLocale: 'en',
  extensionOrigin: 'chrome-extension://ffgojlndpbfcpgpeibknijnjjajoiada',
  appIdIntercom: 'tllz0ki4',
  extensionLink:
    'https://chrome.google.com/webstore/detail/wandify-extension/jhaaipbjdjpkcdlmdepackpalbhjcfib',
  extensionId: 'jhaaipbjdjpkcdlmdepackpalbhjcfib',
  socialLinks: {
    linkedIn: 'https://www.linkedin.com/company/wandify/',
    facebook: '#',
    youtube: 'https://www.youtube.com/@Wandifyio',
    G2: 'https://www.g2.com/products/wandify/reviews'
  },
  wandifyBlogLink: 'https://wandify.io/blog/',
  apiDocumentationLink: 'https://wandifyio.github.io/api/'
};
