import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SelectionService } from '../../../services/selection.service';
import { PaginationInfo } from 'src/app/shared/models';
import { FormControl } from '@angular/forms';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileSearchEnum } from 'src/app/shared/enums';

@Component({
  selector: 'app-profile-mass-selection-dropdown',
  templateUrl: './profile-mass-selection-dropdown.component.html',
  styleUrls: ['./profile-mass-selection-dropdown.component.scss']
})
export class ProfileMassSelectionDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() maxSelectableProfiles = Infinity;

  @Input('paginationInfo') set setPaginationInfo(info: PaginationInfo) {
    const currentPage = info?.page || 1;
    const pageSize = info?.pageSize || ProfileSearchEnum.defaultPageSize;
    this.totalProfiles = info?.numFound || 0;
    const totalPages = Math.ceil(this.totalProfiles / pageSize) || 1;

    if (currentPage < totalPages) {
      this.profilesOnCurrentPage = pageSize;
    } else {
      this.profilesOnCurrentPage = this.totalProfiles - (totalPages - 1) * pageSize;
    }
  }

  @Input() withRange = true;

  @Output() close = new EventEmitter();

  @ViewChild('rangeFrom') rangeFromInputElement?: ElementRef;
  @ViewChild('rangeTo') rangeToInputElement?: ElementRef;

  totalProfiles = 0;
  profilesOnCurrentPage = 0;
  $unsubscribe = new Subject<void>();

  fromControl = new FormControl<string>('');
  toControl = new FormControl<string>('');
  rangeValid = false;

  @HostListener('document:keydown.Escape') escapePressed() {
    this.close.emit();
  }

  constructor(private selection: SelectionService) {}

  ngOnInit(): void {
    merge(this.fromControl.valueChanges, this.toControl.valueChanges)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe(() => {
        this.rangeValid = this.isRangeValid();
      });

    this.rangeValid = this.isRangeValid();
  }

  ngAfterViewInit(): void {
    this.enforceFormating(this.fromControl, this.rangeFromInputElement);
    this.enforceFormating(this.toControl, this.rangeToInputElement);
  }

  private enforceFormating(formControl: FormControl<string>, elementRef: ElementRef): void {
    const element = elementRef?.nativeElement as HTMLInputElement;

    formControl.valueChanges.pipe(takeUntil(this.$unsubscribe)).subscribe((input: string) => {
      const formated = this.formatedNumberString(input);

      if (element && element.selectionStart === element.selectionEnd) {
        const start = element.selectionStart;
        formControl.setValue(formated, { emitEvent: false });
        const withInsertedSpace = input.slice(0, start) + ' ' + input.slice(start);
        // If it equal, that means space was deleted
        const shiftLeft = withInsertedSpace === formated ? 1 : 0;
        const newPosition = Math.max(0, start + formated?.length - input?.length - shiftLeft);
        element.setSelectionRange(newPosition, newPosition);
      } else {
        formControl.setValue(formated, { emitEvent: false });
      }
    });
  }

  isRangeValid(): boolean {
    const from = this.onlyDigits(this.fromControl.value);
    const to = this.onlyDigits(this.toControl.value);

    if (from && to) {
      const fromNum = parseInt(from);
      const toNum = parseInt(to);

      return (
        this.totalProfiles > 1 &&
        this.totalProfiles >= toNum &&
        fromNum < toNum &&
        fromNum > 0 &&
        toNum <= this.maxSelectableProfiles
      );
    }

    return false;
  }

  selectAll(): void {
    this.selection.selectAll();
    this.close.emit(null);
  }

  selectThisPage(): void {
    this.selection.requestThisPageSelection(true, true);
    this.close.emit(null);
  }

  selectRange(): void {
    if (this.isRangeValid()) {
      const from = this.onlyDigits(this.fromControl.value);
      const to = this.onlyDigits(this.toControl.value);

      this.selection.selectRange(+from, +to);
      this.close.emit(null);
    }
  }

  private onlyDigits(input: string = ''): string {
    return input.replace(/\D/g, '');
  }

  private removeLeadingZeros(input: string = ''): string {
    return input.replace(/^0*/g, '');
  }

  /** Group of three number separated by space */
  private formatedNumberString(input?: string): string {
    let digits = this.removeLeadingZeros(this.onlyDigits(input));

    let numStringChars = [];
    for (let i = 0; i < digits.length; i++) {
      numStringChars.push(digits[i]);
      if (digits.length - i !== 1 && (digits.length - i) % 3 === 1) {
        numStringChars.push(' ');
      }
    }

    return numStringChars.join('');
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
